import { ReactComponent as NoFlightsImage } from "assets/images/no-flight.svg";
import { Box, Text } from "components";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { getTranslationsStyle } from "utils/arabisation.util";

const EmptyList = ({ verticalOffset, message }) => {
  const { zIndex } = useTheme();
  const { i18n } = useTranslation();

  return (
    <Box
      d="flex"
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap="m"
      w="100%"
      mT="base"
      pH="base"
      position="absolute"
      top={`${verticalOffset}px`}
      style={{ zIndex: zIndex.above }}
    >
      <NoFlightsImage />
      <Text
        fontWeight="semibold"
        fontSize="m"
        textAlign="center"
        style={getTranslationsStyle(i18n, { textAlign: "center" })}
      >
        {message}
      </Text>
    </Box>
  );
};

export default EmptyList;
