import React, { useCallback, useEffect } from "react";
import { Box } from "components";
import LoadingIndicator from "components/Reusable/LoadingIndicator";
import { FLIGHTS_LIST_POLLING_INTERVAL } from "configs/constants";
import useTabActive from "hooks/useTabActive";
import useTrackPageAnalytics from "hooks/useTrackPageAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetFlightQuery } from "redux/queries/flights.query";
import {
  flightsListSelector,
  setLastSelectedFlightItem,
} from "redux/slices/flightsList.slice";
import styled, { css, keyframes } from "styled-components";

import AirlineInfoBox from "./flightDetails/AirlineInfoBox";
import FavoriteFlights from "./flightDetails/FavoriteFlights";
import FlightInfoBox from "./flightDetails/FlightInfoBox";
import GeneralInfo from "./flightDetails/GeneralInfo";
import Header from "./flightDetails/Header";
import LastUpdate from "./flightDetails/LastUpdate";
import MetroInfoBox from "./flightDetails/MetroInfoBox";
import PushNotificationBox from "./flightDetails/PushNotificationBox";
import TicketInfoBox from "./flightDetails/TicketInfoBox";
import Wayfinding from "./flightDetails/Wayfinding";

const FlightDetailsPage = () => {
  const { selectedFlightDetails = {} } = useSelector(flightsListSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const {
    data: flight = selectedFlightDetails,
    error: getFlightError,
    refetch: refetchFlight,
  } = useGetFlightQuery(id, {
    pollingInterval: FLIGHTS_LIST_POLLING_INTERVAL,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const { terminalToFilter, scrolledAmountInList, hasScannedTicket } =
    location.state || {};

  useTrackPageAnalytics({
    key: hasScannedTicket ? "navigate-details-scan" : "navigate-details",
    name: hasScannedTicket
      ? "User scanned a ticket successfully and entered the details page"
      : "Flights Details Page",
  });

  useEffect(() => {
    dispatch(
      setLastSelectedFlightItem({
        id: flight?.ref_id || null,
        scrolledAmountInList,
      })
    );
  }, [dispatch, flight?.ref_id, scrolledAmountInList]);

  const handleBackClick = useCallback(() => {
    if (terminalToFilter) {
      navigate(`/?terminal=${terminalToFilter}`);
    } else {
      navigate("/");
    }
  }, [terminalToFilter, navigate]);

  const newTime = flight?.new_time;

  if (getFlightError) {
    handleBackClick();
  }

  useTabActive(refetchFlight);

  const isFlightAvailable =
    Object.keys(selectedFlightDetails || {}).length ||
    Object.keys(flight || {}).length;

  if (!isFlightAvailable) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Header
        handleClick={handleBackClick}
        flight={flight}
        isCurrentFlightFavorite={flight.favorite}
        refetchFlight={refetchFlight}
      />
      <FlightDetailsAnimationContainer animate={hasScannedTicket}>
        <Box d="flex" direction="column" gap="l" alignItems="center">
          <TicketInfoBox
            airlineName={flight.airline?.name}
            mediaUrl={flight.media_url}
            newTime={newTime}
            status={flight.status}
            type={flight.type}
            flightTime={flight.flight_time}
            via={flight.via}
            airport={flight.airport}
          />
          <AirlineInfoBox
            airlineName={flight.airline?.name}
            flightNumber={flight.flight_no}
          />
          <FlightInfoBox flight={flight} />
          <Wayfinding flight={flight} />
          <PushNotificationBox flightId={id} />
          <FavoriteFlights
            flight={flight}
            isCurrentFlightFavorite={flight.favorite}
            refetchFlight={refetchFlight}
          />
          <MetroInfoBox flightInfo={flight.info} />
          <GeneralInfo flight={flight} />
          <LastUpdate lastUpdated={flight?.last_updated} />
        </Box>
      </FlightDetailsAnimationContainer>
    </>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const FlightDetailsAnimationContainer = styled.div`
  position: ${(props) => (props.animate ? "static" : "relative")};
  animation: ${(props) =>
    props.animate
      ? css`
          ${slideUp} 0.8s ease-in-out forwards
        `
      : "unset"};
`;

export default FlightDetailsPage;
