import React from "react";
import { Box, PrimaryButton, Text } from "components";
import { FLIGHTS_LIST_POLLING_INTERVAL } from "configs/constants";
import useTrackPageAnalytics from "hooks/useTrackPageAnalytics";
import InfoIcon from "local_modules/smalt/src/Icons/components/base/Info";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGetSettingsQuery } from "redux/queries/settings.query";
import {
  clearWayfindingState,
  modalsSelector,
} from "redux/slices/modals.slice";
import { getTranslationsStyle, isArabic } from "utils/arabisation.util";

const WayfindingModalContent = () => {
  const { sendAnalytics } = useTrackPageAnalytics();
  const dispatch = useDispatch();
  const { data: settingsData = {} } = useGetSettingsQuery(undefined, {
    refetchOnReconnect: true,
    pollingInterval: FLIGHTS_LIST_POLLING_INTERVAL,
  });

  const wayfindingMapping = settingsData.wayfindingMapping || {};
  const { base_url, token, site_id } = wayfindingMapping;

  const { t, i18n } = useTranslation();
  const shouldReverseOrder = isArabic(i18n);

  const { wayfindingPayload } = useSelector(modalsSelector);
  const { type, payload } = wayfindingPayload || {};

  const handleRedirectToPointr = () => {
    if (!base_url || !token || !site_id) {
      // TODO Implement a toast to show that Pointr is not configured
      return console.log("Could not redirect to Pointr!");
    }
    if (!payload) {
      sendAnalytics({
        key: "wayfinding-redirect-menu",
        type: "event",
        name: "User redirected to Pointr from Main Menu",
      });
      return window.open(`${base_url}?t=${token}&s=${site_id}`, "_blank");
    }
    const poiId = wayfindingMapping[type]?.[payload];

    if (!poiId) {
      // TODO Implement a toast to show that Pointr is not configured
      return console.log("Could not redirect to POI!");
    }

    const URIToRedirect = `${base_url}?t=${token}&s=${site_id}&p=${poiId}`;

    sendAnalytics({
      key: "wayfinding-redirect-details",
      type: "event",
      name: "User redirected to Pointr from Details Page",
      uri: URIToRedirect,
    });

    return window.open(URIToRedirect, "_blank");
  };

  const handleCloseClick = () => {
    sendAnalytics({
      key: "wayfinding-close",
      type: "event",
      name: "Clicked on Close Wayfinding Modal",
    });
    dispatch(clearWayfindingState(false));
  };

  return (
    <Box
      mV="m"
      d="flex"
      direction="column"
      gap="m"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        d="flex"
        gap="s"
        alignItems="center"
        direction={shouldReverseOrder ? "row-reverse" : undefined}
      >
        <InfoIcon />
        <Text fontSize="l" color="otherText" style={getTranslationsStyle(i18n)}>
          {t("wayfinding.modal_title")}
        </Text>
      </Box>
      <Text
        color="textSecondary"
        fontSize="m"
        mH="l"
        textAlign="center"
        style={getTranslationsStyle(i18n)}
      >
        {t("wayfinding.modal_body")}
      </Text>
      <Box
        d="flex"
        justifyContent="space-between"
        gap="base"
        w="100%"
        pH="l"
        style={{ cursor: "pointer" }}
      >
        <Box
          d="flex"
          justifyContent="center"
          alignItems="center"
          flex="1"
          onClick={handleCloseClick}
        >
          <Text color="primary" style={getTranslationsStyle(i18n)}>
            {t("installation_modals.close")}
          </Text>
        </Box>
        <PrimaryButton
          style={{ flex: "1" }}
          h="40px"
          onClick={handleRedirectToPointr}
        >
          <Text style={getTranslationsStyle(i18n)}>
            {t("wayfinding.continue")}
          </Text>
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default WayfindingModalContent;
