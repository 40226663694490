import React from "react";
import { ReactComponent as MapIcon } from "assets/images/map.svg";
import { ReactComponent as PlaneIcon } from "assets/images/plane.svg";
import { ReactComponent as ScanIcon } from "assets/images/scan-ticket.svg";
import { ReactComponent as FavoritesIcon } from "assets/images/star.svg";
import { Box, Text } from "components";
import { CATEGORY } from "configs/constants";
import useTrackPageAnalytics from "hooks/useTrackPageAnalytics";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  flightsListSelector,
  setCategory,
} from "redux/slices/flightsList.slice";
import { setWayfingModalOpen } from "redux/slices/modals.slice";
import { useTheme } from "styled-components";

const ActionsMenu = ({ setIsVideoModalOpen }) => {
  const { sendAnalytics } = useTrackPageAnalytics();

  const { t } = useTranslation();
  const { category } = useSelector(flightsListSelector);
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Box
      w="100%"
      h="80px"
      d="flex"
      alignItems="center"
      position="fixed"
      bottom="0"
      backgroundColor="accent"
      style={{ zIndex: theme.zIndex.above }}
    >
      <Box w="100%" d="flex" justifyContent="space-around" alignItems="center">
        <Box
          d="flex"
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          gap="xxs"
          mV="base"
          style={{
            cursor: "pointer",
          }}
          onClick={() => dispatch(setCategory(CATEGORY.FLIGHTS))}
        >
          <PlaneIcon
            style={{
              stroke:
                category === CATEGORY.FLIGHTS ? theme.colors.accent_2 : null,
              fill:
                category === CATEGORY.FLIGHTS ? theme.colors.accent_2 : null,
            }}
          />
          <Text
            fontWeight="bold"
            color={
              category === CATEGORY.FLIGHTS ? "accent_2" : "textQuaternary"
            }
          >
            {t("flights_list.flightsCount_flights")}
          </Text>
        </Box>
        <Box
          d="flex"
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          gap="xxs"
          mV="base"
          style={{
            cursor: "pointer",
          }}
          onClick={() => dispatch(setCategory(CATEGORY.FAVORITES))}
        >
          <FavoritesIcon
            fill={
              category === CATEGORY.FAVORITES
                ? theme.colors.accent_2
                : theme.colors.textQuaternary
            }
            stroke={
              category === CATEGORY.FAVORITES
                ? theme.colors.accent_2
                : theme.colors.textQuaternary
            }
          />
          <Text
            fontWeight="bold"
            color={
              category === CATEGORY.FAVORITES ? "accent_2" : "textQuaternary"
            }
          >
            {t("header.tab_favorites")}
          </Text>
        </Box>
        <Box
          d="flex"
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          gap="xxs"
          mV="base"
          onClick={() => {
            sendAnalytics({
              key: "scanner-click",
              type: "event",
              name: "Clicked on Scanner in Main Menu",
            });
            setIsVideoModalOpen(true);
          }}
        >
          <ScanIcon />
          <Text fontWeight="bold" color="textQuaternary">
            {t("header.scanner")}
          </Text>
        </Box>
        <Box
          d="flex"
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          gap="xxs"
          mV="base"
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            sendAnalytics({
              key: "wayfinding-click",
              type: "event",
              name: "Clicked on Wayfinding in Main Menu",
            });
            dispatch(setWayfingModalOpen(true));
          }}
        >
          <MapIcon width={32} height={32} />
          <Text fontWeight="bold" color="textQuaternary">
            {t("header.airport_map")}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ActionsMenu;
