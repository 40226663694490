import React from "react";
import { Box, Icon, Text } from "components";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { getTranslationsStyle } from "utils/arabisation.util";

export const NO_MATCH_MESSAGE_HEIGHT = 200;

const NoMatchMessage = ({ verticalOffset }) => {
  const { t, i18n } = useTranslation();
  const { zIndex } = useTheme();
  return (
    <Box
      d="flex"
      justifyContent="center"
      alignItems="center"
      direction="column"
      gap="m"
      mT="m"
      mB="m"
      mH="m"
      position="absolute"
      top={`${verticalOffset}px`}
      style={{ zIndex: zIndex.above }}
    >
      <Icon size="xl" name="Search"></Icon>
      <Text pH="m" textAlign="center" style={getTranslationsStyle(i18n)}>
        {t("flights_list.noMatch")}
      </Text>
    </Box>
  );
};

export default NoMatchMessage;
