import React, { useState } from "react";
import { Modal } from "components";
import {
  ACTIVE_TAB,
  CATEGORY,
  START_SEARCH_CONTAIN_LENGTH,
} from "configs/constants";
import useTrackPageAnalytics from "hooks/useTrackPageAnalytics";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedFlightDetails } from "redux/slices/flightsList.slice";
import { filterFlightsBySearch } from "utils/flightList.util";

import CameraPermissionModalContent from "./ticketScanner/CameraPermissionModalContent";
import TicketScannerModalContent from "./ticketScanner/TicketScannerModalContent";

const TicketScanner = ({ flights, isVideoModalOpen, setIsVideoModalOpen }) => {
  const { sendAnalytics } = useTrackPageAnalytics();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCameraPermissionModalOpen, setIsCameraPermissionModalOpen] =
    useState(false);
  const [isScanUnsuccessful, setIsScanUnsuccessful] = useState(false);

  const handleGetTicketNumber = (ticketNumber) => {
    if (!ticketNumber) {
      return setIsScanUnsuccessful(true);
    }

    const searchForScannedTicket = filterFlightsBySearch(
      ticketNumber,
      { flights, favoriteFlights: null },
      {
        category: CATEGORY.FLIGHTS,
        activeTab: ACTIVE_TAB.ALL,
        terminalToFilter: null,
        startSearchContainLength: START_SEARCH_CONTAIN_LENGTH,
      }
    );

    if (searchForScannedTicket.length > 2 || !searchForScannedTicket.length) {
      sendAnalytics({
        key: "scan-no-flights",
        type: "event",
        name: `User scanned ${ticketNumber} but no flight was found`,
      });
      setIsScanUnsuccessful(true);
      return;
    }

    if (searchForScannedTicket.length === 2) {
      //If two flights are found, the departure takes higher priority
      const foundFlight = searchForScannedTicket.find(
        (flight) => flight.type === "departure"
      );
      dispatch(setSelectedFlightDetails(foundFlight));
      navigate(`/flight/${foundFlight.ref_id}`, {
        state: {
          hasScannedTicket: true,
        },
      });

      return;
    }

    const foundFlight = searchForScannedTicket[0];
    dispatch(setSelectedFlightDetails(foundFlight));
    navigate(`/flight/${foundFlight.ref_id}`, {
      state: {
        hasScannedTicket: true,
      },
    });
  };

  return (
    <>
      {isVideoModalOpen && (
        <TicketScannerModalContent
          setIsVideoModalOpen={setIsVideoModalOpen}
          isScanUnsuccessful={isScanUnsuccessful}
          setIsScanUnsuccessful={setIsScanUnsuccessful}
          handleGetTicketNumber={handleGetTicketNumber}
          setIsCameraPermissionModalOpen={setIsCameraPermissionModalOpen}
        />
      )}
      <Modal
        isOpen={isCameraPermissionModalOpen}
        onRequestClose={() => setIsVideoModalOpen(false)}
        withCloseButton={false}
        w="90%"
        verticalPosition="center"
      >
        <CameraPermissionModalContent
          handleCloseModal={setIsCameraPermissionModalOpen}
          setIsVideoModalOpen={setIsVideoModalOpen}
        />
      </Modal>
    </>
  );
};

export default TicketScanner;
