import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isWaydingModalOpen: false,
  wayfindingPayload: null,
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setWayfingModalOpen: (state, { payload }) => {
      state.isWaydingModalOpen = payload;
    },
    setWayfindingPayload: (state, { payload }) => {
      state.wayfindingPayload = payload;
    },
    clearWayfindingState: (state) => {
      state.isWaydingModalOpen = false;
      state.wayfindingPayload = null;
    },
  },
});

export const {
  setWayfingModalOpen,
  clearWayfindingState,
  setWayfindingPayload,
} = modalsSlice.actions;

export const modalsSelector = (state) => state.modals;

export default modalsSlice.reducer;
