import React from "react";
import { ReactComponent as InfoIcon } from "assets/images/info.svg";
import { Box, Text } from "components";
import { useTranslation } from "react-i18next";
import { getTranslationsStyle, isArabic } from "utils/arabisation.util";

const TryAgainModalContent = ({
  setIsScanUnsuccessful,
  setIsTryAgainModalOpen,
}) => {
  const { t, i18n } = useTranslation();

  const shouldReverseOrder = isArabic(i18n);

  return (
    <Box
      mV="m"
      d="flex"
      direction="column"
      gap="m"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        d="flex"
        gap="s"
        alignItems="center"
        direction={shouldReverseOrder ? "row-reverse" : undefined}
      >
        <InfoIcon />
        <Text fontSize="l" color="otherText" style={getTranslationsStyle(i18n)}>
          {t("scan.cameraOverlay_flightNotFound")}
        </Text>
      </Box>
      <Text
        color="textSecondary"
        fontSize="m"
        mH="l"
        style={getTranslationsStyle(i18n)}
      >
        {t("scan.cameraOverlay_notFoundDescription")}
      </Text>
      <Box
        onClick={() => {
          setIsScanUnsuccessful(false);
          setIsTryAgainModalOpen(false);
        }}
      >
        <Text fontSize="h1" color="primary" style={getTranslationsStyle(i18n)}>
          {t("scan.cameraOverlay_tryAgainButton")}
        </Text>
      </Box>
    </Box>
  );
};

export default TryAgainModalContent;
