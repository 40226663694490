// prettier-ignore
import store, { persistor } from "./redux/store";
//The import above must be at the top in order for RTK baseAPI to work
import React, { Suspense, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Router from "routes/Router";

import ErrorBoundary from "./components/ErrorBoundary";
import ErrorBoundaryFallback from "./components/ErrorBoundaryFallback";
import PocketFlightsThemeProvider from "./components/ThemeProvider";

import "react-toastify/dist/ReactToastify.css";
//Overrides react-toastify styling
import "./assets/styles/custom-toastify-styles.css";
import LoadingIndicator from "components/Reusable/LoadingIndicator";
import ToastContainer from "components/ToastContainer";
import TranslationsError from "components/TranslationsError";
import { initI18n } from "i18n";
import ModalsContainer from "pages/ModalsContainer";

const App = () => {
  const [i18nError, setI18nError] = useState(null);

  useEffect(() => {
    initI18n().catch((error) => setI18nError(error));
    sessionStorage.setItem("isFirstTimeOpened", "true");
  }, []);

  //Translations fetch request error
  if (i18nError) {
    return (
      <PersistGate loading={true} persistor={persistor}>
        <Provider store={store}>
          <PocketFlightsThemeProvider>
            <TranslationsError
              error={i18nError}
              resetErrorBoundary={() => window.location.reload()}
            />
          </PocketFlightsThemeProvider>
        </Provider>
      </PersistGate>
    );
  }

  return (
    <PersistGate loading={true} persistor={persistor}>
      <Provider store={store}>
        <PocketFlightsThemeProvider>
          <Suspense fallback={<LoadingIndicator height="100vh" />}>
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <ToastContainer
                draggablePercent={40}
                draggableDirection="y"
                autoClose={1300}
                closeButton={false}
                hideProgressBar={true}
              />
              <ModalsContainer />
              <Router />
            </ErrorBoundary>
          </Suspense>
        </PocketFlightsThemeProvider>
      </Provider>
    </PersistGate>
  );
};

export default App;
