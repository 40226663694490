import {
  ACTIVE_TAB,
  CATEGORY,
  FLIGHT_TYPE,
  START_SEARCH_CONTAIN_LENGTH,
} from "configs/constants";

const normalizeString = (str) => str.toUpperCase().split(" ").join("");

export const filterFlightsBySearch = (searchKeyword, allFlights, options) => {
  const { flights, favoriteFlights } = allFlights;
  const {
    category,
    activeTab,
    terminalToFilter,
    startSearchContainLength = START_SEARCH_CONTAIN_LENGTH,
  } = options;

  const keyword = normalizeString(searchKeyword);

  if (category === CATEGORY.FAVORITES) {
    if (!favoriteFlights?.length) {
      return [];
    }

    return favoriteFlights.filter((flight) => {
      if (activeTab !== ACTIVE_TAB.ALL && flight.type !== activeTab) {
        return false;
      }
      return true;
    });
  }

  const preliminaryResult = flights.filter((flight) => {
    if (activeTab !== ACTIVE_TAB.ALL && flight.type !== activeTab) {
      return false;
    }

    const isInTerminal =
      flight.terminal_name === terminalToFilter || terminalToFilter === null;

    // Fuzzy match for flight number
    if (keyword.length > startSearchContainLength) {
      const searchChars = keyword.split("");
      // Store how many times the search digit is found in the flightNumber
      let history = {};

      const foundCharsInFlightNumber = searchChars.every((char) => {
        if (!history[char]) {
          history[char] = 1;
        } else {
          history[char] += 1;
        }

        const flightNumber = normalizeString(flight.flight_no);
        const countInFlightNumber = (
          flightNumber.match(new RegExp(char, "g")) || []
        ).length;
        return countInFlightNumber >= history[char];
      });

      if (foundCharsInFlightNumber) return isInTerminal;
    }

    // Include logic for other fields
    const flightNumber = normalizeString(flight.flight_no);
    const airportCode = normalizeString(flight.airport.code);
    const flightDestination = normalizeString(
      flight.type === FLIGHT_TYPE.DEPARTURE ? flight.destination : flight.origin
    );
    const airline = flight.airline?.name
      ? normalizeString(flight.airline.name)
      : "";
    const viaKeywords = flight.via_keywords
      ? normalizeString(flight.via_keywords)
      : "";

    const matches = [
      flightNumber.includes(keyword),
      airportCode.includes(keyword),
      flightDestination.includes(keyword),
      airline.includes(keyword),
      viaKeywords.includes(keyword),
    ];

    return matches.some((match) => match) && isInTerminal;
  });

  // Find an exact match for the flight number
  const exactMatch = preliminaryResult.find(
    (flight) => normalizeString(flight.flight_no) === keyword
  );

  return exactMatch ? [exactMatch] : preliminaryResult;
};
