import React from "react";
import { ReactComponent as NewWindowIcon } from "assets/images/new-window.svg";
import { Box, Text } from "components";
import { FLIGHT_TYPE } from "configs/constants";
import useTrackPageAnalytics from "hooks/useTrackPageAnalytics";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  setWayfindingPayload,
  setWayfingModalOpen,
} from "redux/slices/modals.slice";
import styled from "styled-components";
import { getTranslationsStyle, isArabic } from "utils/arabisation.util";

const WayfindingBoxStyled = styled(Box)`
  width: calc(100% - ${(props) => props.theme.spaces.xxl});
  margin-top: -${(props) => props.theme.spaces.base};
  margin-bottom: -${(props) => props.theme.spaces.xs};
`;

const Wayfinding = ({ flight }) => {
  const { sendAnalytics } = useTrackPageAnalytics();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const noActualGateSpecified = flight.gate?.includes("Gate at");

  const handleGoToPointr = () => {
    if (flight.type === FLIGHT_TYPE.DEPARTURE) {
      dispatch(setWayfindingPayload({ type: "gates", payload: flight.gate }));
    } else {
      dispatch(setWayfindingPayload({ type: "belts", payload: flight.belt }));
    }
    sendAnalytics({
      key: "wayfinding-open-details",
      type: "event",
      name: "Clicked on Wayfinding in Details Page",
    });

    dispatch(setWayfingModalOpen(true));
  };

  const shouldReverseOrder = isArabic(i18n);

  if (noActualGateSpecified) {
    return null;
  }

  return (
    <WayfindingBoxStyled>
      <Text fontSize="m" f>
        {flight.type === FLIGHT_TYPE.DEPARTURE
          ? t("wayfinding.title_departures")
          : t("wayfinding.title_arrivals")}
      </Text>
      <Text mV="xs" color="textSecondary">
        {flight.type === FLIGHT_TYPE.DEPARTURE
          ? t("wayfinding.description_departures")
          : t("wayfinding.description_arrivals")}
      </Text>
      <Box
        d="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor="primary"
        borderRadius="m"
        style={{ cursor: "pointer" }}
        onClick={handleGoToPointr}
      >
        <Box
          d="flex"
          alignItems="center"
          gap="xs"
          pV="xs"
          direction={shouldReverseOrder ? "row-reverse" : undefined}
        >
          <NewWindowIcon />
          <Text style={getTranslationsStyle(i18n)} color="textQuaternary">
            {t("wayfinding.cta_button")}
          </Text>
        </Box>
      </Box>
    </WayfindingBoxStyled>
  );
};

export default Wayfinding;
