import { createSlice } from "@reduxjs/toolkit";
import { ACTIVE_TAB, CATEGORY } from "configs/constants";

const initialState = {
  activeTab: ACTIVE_TAB.ALL,
  category: CATEGORY.FLIGHTS,
  searchKeyword: "",
  selectedFlightDetails: null,
  visitedForFirstTime: true,
  lastSelectedFlightItem: {
    id: null,
    scrolledAmountInList: null,
  },
};

export const flightsListSlice = createSlice({
  name: "flightsList",
  initialState,
  reducers: {
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    setCategory: (state, { payload }) => {
      state.category = payload;
    },
    setSearchKeyword: (state, { payload }) => {
      state.searchKeyword = payload;
    },
    setSelectedFlightDetails: (state, { payload }) => {
      state.selectedFlightDetails = payload;
    },
    setVisitedForFirstTime: (state, { payload }) => {
      state.visitedForFirstTime = payload;
    },
    setLastSelectedFlightItem: (state, { payload }) => {
      state.lastSelectedFlightItem = payload;
    },
    resetLastSelectedFlightItem: (state) => {
      state.lastSelectedFlightItem = {
        id: null,
        scrolledAmountInList: null,
      };
    },
    triggerScrollToTop: (state, { payload }) => {
      state.lastSelectedFlightItem = {
        ...state.lastSelectedFlightItem,
        scrolledAmountInList: payload,
      };
    },
  },
});

export const {
  setActiveTab,
  setSearchKeyword,
  setSelectedFlightDetails,
  setLastSelectedFlightItem,
  triggerScrollToTop,
  setVisitedForFirstTime,
  resetLastSelectedFlightItem,
  setCategory,
} = flightsListSlice.actions;

export const flightsListSelector = (state) => state.flightsList;

export default flightsListSlice.reducer;
