import { useEffect, useRef } from "react";
import { useSubmitAnalyticMutation } from "redux/queries/analytics";

const useTrackPageAnalytics = (
  initialAnalyticData,
  { isFirstTimeOpened, uniqueDeviceIdentifier } = {
    isFirstTimeOpened: null,
    uniqueDeviceIdentifier: null,
  }
) => {
  const [submitAnalytic, { error }] = useSubmitAnalyticMutation();
  const lastSentURI = useRef(null);

  const path = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search).toString();
  const URI = path + searchParams;

  useEffect(() => {
    // Check if analytics data exists and the current URI hasn't been tracked yet
    if (initialAnalyticData && lastSentURI.current !== URI) {
      submitAnalytic({
        type: "hit",
        uri: URI,
        ...initialAnalyticData,
      }).then(() => {
        if (isFirstTimeOpened !== null) {
          sessionStorage.setItem("isFirstTimeOpened", "false");
          sessionStorage.setItem(
            "uniqueDeviceIdentifier",
            uniqueDeviceIdentifier
          );
        }
      });
      lastSentURI.current = URI;
    }
  }, [
    initialAnalyticData,
    URI,
    submitAnalytic,
    uniqueDeviceIdentifier,
    isFirstTimeOpened,
  ]);

  const sendAnalytics = (analyticData) => {
    submitAnalytic(analyticData);
  };

  if (error) {
    console.error("Could not send analytics: " + JSON.stringify(error));
  }

  return { sendAnalytics };
};

export default useTrackPageAnalytics;
