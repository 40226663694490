import { useEffect, useState } from "react";
import { CATEGORY, FLIGHTS_LIST_POLLING_INTERVAL } from "configs/constants";
import usePushNotification from "hooks/usePushNotification";
import useRefetchInterval from "hooks/useRefetchInterval";
import useTabActive from "hooks/useTabActive";
import useTrackPageAnalytics from "hooks/useTrackPageAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  useGetFavoriteFlightsQuery,
  useGetFlightsQuery,
} from "redux/queries/flights.query";
import { useGetSettingsQuery } from "redux/queries/settings.query";
import {
  flightsListSelector,
  setCategory,
  setSelectedFlightDetails,
  setVisitedForFirstTime,
} from "redux/slices/flightsList.slice";
import { checkAntiScraping } from "services/cookie.service";
import styled from "styled-components";
import { filterFlightsBySearch } from "utils/flightList.util";

import ActionsMenu from "./flightList/ActionsMenu";
import FlightsList from "./flightList/FlightsList";
import LanguageDrawer from "./flightList/LanguageDrawer";
import TicketScanner from "./flightList/TicketScanner";

const FlightsListPage = () => {
  const uniqueDeviceIdentifier = useSelector(
    (state) => state.initialize.uniqueDeviceIdentifier
  );
  const sessionStorageUniqueDeviceIdentifier = sessionStorage.getItem(
    "uniqueDeviceIdentifier"
  );
  const isFirstTimeOpened =
    sessionStorage.getItem("isFirstTimeOpened") === "true";
  const shouldPerformInitialAnalyticsCall =
    isFirstTimeOpened && sessionStorageUniqueDeviceIdentifier === null;

  const analyticsDetails = shouldPerformInitialAnalyticsCall
    ? { name: "Opened Pocket Flights", key: "pocketflights-opened" }
    : { name: "Flights List Page", key: "navigate-menu" };

  useTrackPageAnalytics(analyticsDetails, {
    isFirstTimeOpened,
    uniqueDeviceIdentifier,
  });

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [queryParams, setQueryParams] = useSearchParams();
  const terminalToFilter = queryParams.get("terminal");

  const dispatch = useDispatch();
  const { activeTab, searchKeyword, visitedForFirstTime, category } =
    useSelector(flightsListSelector);

  const isWebScraperDetected = !checkAntiScraping();

  const { data: settingsData = {}, refetch: refetchSettings } =
    useGetSettingsQuery(undefined, {
      refetchOnReconnect: true,
      pollingInterval: FLIGHTS_LIST_POLLING_INTERVAL,
      skip: isWebScraperDetected,
    });

  const {
    reloadIntervalSeconds,
    startSearchContainLength,
    terminals: terminalsDropdownOptionList = [],
  } = settingsData;

  let {
    data: flights = [],
    refetch: refetchFlights,
    isLoading: areFlightsLoading,
  } = useGetFlightsQuery(undefined, {
    skip: isWebScraperDetected,
    refetchOnMountOrArgChange: true,
  });

  const { data: queryFavoriteFlights = [] } = useGetFavoriteFlightsQuery(
    undefined,
    {
      skip: isWebScraperDetected || !flights.length,
      refetchOnMountOrArgChange: true,
    }
  );

  const favoriteFlights = flights.filter((flight) => {
    return queryFavoriteFlights.includes(flight.ref_id);
  });

  useEffect(() => {
    //Set active tab to Favorites if it's first time opening the app and persisted favorite flights were found
    if (visitedForFirstTime && favoriteFlights.length) {
      dispatch(setCategory(CATEGORY.FAVORITES));
      dispatch(setVisitedForFirstTime(false));
    }
  }, [activeTab, dispatch, favoriteFlights, visitedForFirstTime]);

  //Reset refetch interval for flights
  useRefetchInterval({
    dispatch,
    setSelectedFlightDetails,
    reloadIntervalSeconds,
    refetchFlights,
  });

  //Refetch flights when user switches back to pocketFlights browser tab
  useTabActive(refetchFlights);

  const filteredFlights = filterFlightsBySearch(
    searchKeyword,
    { flights, favoriteFlights },
    { category, activeTab, terminalToFilter, startSearchContainLength }
  );

  const filteredFlightsWithoutTerminalFilterApplied = filterFlightsBySearch(
    searchKeyword,
    { flights, favoriteFlights: null },
    { category, activeTab, startSearchContainLength, terminalToFilter: null }
  );

  usePushNotification({
    refetchFlights,
    refetchSettings,
    dispatch,
    setSelectedFlightDetails,
    filteredFlights,
  });

  return (
    <FlightListStyled>
      <FlightsList
        terminalsDropdownOptionList={terminalsDropdownOptionList}
        flights={filteredFlights}
        unfilteredFlights={filteredFlightsWithoutTerminalFilterApplied}
        hasSearched={!!searchKeyword}
        terminal={terminalToFilter}
        areFlightsLoading={areFlightsLoading}
        setQueryParams={setQueryParams}
      />
      <LanguageDrawer
        refetchFlights={refetchFlights}
        refetchSettings={refetchSettings}
      />
      <TicketScanner
        flights={flights}
        isVideoModalOpen={isVideoModalOpen}
        setIsVideoModalOpen={setIsVideoModalOpen}
      ></TicketScanner>
      <ActionsMenu
        flights={flights}
        setIsVideoModalOpen={setIsVideoModalOpen}
      />
    </FlightListStyled>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const FlightListStyled = styled.div`
  //Hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  -webkit-overscroll-behavior-y: none;
  -webkit-overscroll-behavior-x: none;

  touch-action: pan-y;

  position: relative;
  /* TODO See if switching to overflow hidden has some consequences */
  /* overflow: scroll; */
  overflow: hidden;
  max-height: 100svh; //svh - small view height (for phones)
`;

export default FlightsListPage;
