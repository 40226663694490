import React from "react";
import CloudsImage from "assets/images/clouds.png";
import { ReactComponent as StarIcon } from "assets/images/star.svg";
import { Box, GhostIconButton } from "components";
import Toast from "components/Reusable/Toast";
import { ACTIVE_TAB, CATEGORY, TOAST_CONFIG } from "configs/constants";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  resetLastSelectedFlightItem,
  setActiveTab,
  setCategory,
} from "redux/slices/flightsList.slice";
import {
  addFlightToFavorites,
  removeFlightFromFavorites,
} from "services/favorite-flights.service";
import styled, { useTheme } from "styled-components";

const Header = ({
  handleClick,
  flight: detailsFlight,
  isCurrentFlightFavorite,
  refetchFlight,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  //TODO Gather some analytics to find out if this duplicate of favorite flights is useful
  const handleFavoriteFlightClick = () => {
    if (isCurrentFlightFavorite) {
      toast(
        <Toast
          type="success"
          position="top"
          message={t("favorite.removedFromFavorites")}
        />,
        TOAST_CONFIG
      );
      removeFlightFromFavorites(detailsFlight.ref_id).then((res) => {
        refetchFlight();
      });
    } else {
      dispatch(resetLastSelectedFlightItem());
      toast(
        <Toast
          type="success"
          position="top"
          message={t("favorite.addedToFavorites")}
        />,
        TOAST_CONFIG
      );
      addFlightToFavorites(detailsFlight.ref_id).then(() => {
        refetchFlight();
      });
      dispatch(setCategory(CATEGORY.FAVORITES));
      dispatch(setActiveTab(ACTIVE_TAB.ALL));
    }
  };

  return (
    <HeaderStyled url={CloudsImage}>
      <Box
        d="flex"
        justifyContent="space-between"
        bB
        borderColor="headerBorder"
        h={theme.spaces.xxl}
      >
        <GhostIconButton
          pL="m"
          iconSize="xl"
          name="ChevronLeft"
          color="textQuaternary"
          onClick={handleClick}
        />
        <Box
          d="flex"
          alignItems="center"
          pR="m"
          onClick={handleFavoriteFlightClick}
        >
          <StarIcon
            fill={
              isCurrentFlightFavorite
                ? theme.colors.backgroundQuaternary
                : "none"
            }
            stroke={theme.colors.backgroundQuaternary}
            width={24}
            height={24}
            style={{ cursor: "pointer" }}
          />
        </Box>
      </Box>
    </HeaderStyled>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const HeaderStyled = styled.div`
  height: ${(props) => props.theme.flightDetailsHeaderHeight};
  background-color: ${(props) => props.theme.colors.secondary};
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export default Header;
