import React from "react";
import { Modal } from "components";
import { useDispatch, useSelector } from "react-redux";
import {
  clearWayfindingState,
  modalsSelector,
} from "redux/slices/modals.slice";

import WayfindingModalContent from "./modalsContainer/WayfindingModalContent";

const ModalsContainer = () => {
  const { isWaydingModalOpen } = useSelector(modalsSelector);
  const dispatch = useDispatch();
  return (
    <Modal
      isOpen={isWaydingModalOpen}
      onRequestClose={() => dispatch(clearWayfindingState(false))}
      withCloseButton={false}
      w="90%"
      verticalPosition="center"
    >
      <WayfindingModalContent />
    </Modal>
  );
};

export default ModalsContainer;
