export const DEFAULT_REALM_NAME = "Demo Airport";
export const DEFAULT_REALM_CODE = "DMO";
export const START_SEARCH_CONTAIN_LENGTH = 2;
export const FLIGHTS_LIST_POLLING_INTERVAL = 60000;
export const STICKY_OFFSET = 150;

// TODO Will be moved in BE settings
export const SHOW_LANGUAGE_FLAGS = false;

export const LANGUAGE_CODES = {
  ENGLISH: "en",
  ARABIC: "ar",
};

export const ACTIVE_TAB = {
  ALL: "all",
  DEPARTURE: "departure",
  ARRIVAL: "arrival",
};

export const CATEGORY = {
  FLIGHTS: "flights",
  FAVORITES: "favorites",
};

export const FLIGHT_TYPE = {
  DEPARTURE: "departure",
  ARRIVAL: "arrival",
};

export const DEFAULT_PRIMARY_COLOR = "#575fcc";
export const DEFAULT_SECONDARY_COLOR = "#212151";

export const REALMS_FULL_NAME = {
  DUBAI: "Dubai",
  DEFAULT: "default",
};

export const REALMS_CODE = {
  DUBAI: "dxb",
  DEV: "dev",
};

export const NOTIFICATION_CASES = {
  IOS_VERSION_BELOW_16_4: "IOS_VERSION_BELOW_16_4",
  DEFAULT_BROWSER_NOT_SAFARI: "DEFAULT_BROWSER_NOT_SAFARI",
  APP_NOT_INSTALLED_AS_PWA: "APP_NOT_INSTALLED_AS_PWA",
  IS_REGULAR_DEVICE_NOT_INSTALLED_AS_PWA:
    "IS_REGULAR_DEVICE_NOT_INSTALLED_AS_PWA",
  USER_IS_NOT_BROWSING_MOBILE: "USER_IS_NOT_BROWSING_MOBILE",
};

export const TOAST_CONFIG = {
  position: "top-center",
  closeOnClick: false,
  pauseOnHover: true,
};

export const VARIANTS = {
  LOADING_INDICATOR: {
    ABSOLUTE: "ABSOLUTE",
    STATIC: "STATIC",
  },
  AIRLINE_LOGO: {
    DETAILS_PAGE: "DETAILS_PAGE",
    FLIGHTS_LIST: "FLIGHTS_LIST",
  },
};
