import React, { useEffect, useRef, useState } from "react";
import LoadingIndicator from "components/Reusable/LoadingIndicator";
import { CATEGORY, VARIANTS } from "configs/constants";
import useWindowDimensions from "hooks/windowDimensions";
import EmptyList from "pages/flightList/flightsContainer/EmptyList";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { List } from "react-virtualized";
import { flightsListSelector } from "redux/slices/flightsList.slice";
import styled from "styled-components";

import FlightItem, {
  DEFAULT_FLIGHT_CARD_HEIGHT,
  VIA_FLIGHT_CARD_HEIGHT,
} from "./flightsContainer/FlightItem";
import Header from "./Header";
import NoMatchMessage, { NO_MATCH_MESSAGE_HEIGHT } from "./NoMatchMessage";

const FlightsList = ({
  flights = [],
  unfilteredFlights = [],
  areFlightsLoading,
  terminal,
  hasSearched,
  terminalsDropdownOptionList,
  setQueryParams,
}) => {
  const dispatch = useDispatch();
  const listRef = useRef();
  const { activeTab, category } = useSelector(flightsListSelector);
  const { t } = useTranslation();

  const { height, width } = useWindowDimensions();

  const [scrollPosition, setScrollPosition] = useState(0);
  const [spacerHeight, setSpacerHeight] = useState(0);

  const handleScroll = ({ scrollTop }) => {
    setScrollPosition(scrollTop);
  };

  const recomputeFirstRowHeight = () => {
    if (listRef.current) {
      listRef.current.recomputeRowHeights(0);
    }
  };

  const { lastSelectedFlightItem } = useSelector(flightsListSelector);
  const lastScrolledAmountInList = lastSelectedFlightItem.scrolledAmountInList;

  const noFlights = !unfilteredFlights.length && !flights.length;
  const noFavoriteFlights = category === CATEGORY.FAVORITES && !flights.length;
  const noTerminalFlights = !flights.length && unfilteredFlights.length;

  const flightsToRender = noTerminalFlights ? unfilteredFlights : flights;

  useEffect(() => {
    //COMPUTE SPACER HEIGHT
    const headerElement = document.getElementById("header");
    if (headerElement) {
      const { height } = headerElement.getBoundingClientRect();

      if (noTerminalFlights) {
        setSpacerHeight(height + NO_MATCH_MESSAGE_HEIGHT);
        return;
      }

      setSpacerHeight(height);
    }
  }, [hasSearched, noTerminalFlights, activeTab]);

  useEffect(() => {
    recomputeFirstRowHeight();
  }, [spacerHeight, activeTab]);

  useEffect(() => {
    if (listRef.current && lastScrolledAmountInList !== null) {
      listRef.current.scrollToPosition(lastScrolledAmountInList);
    }
  }, [dispatch, lastScrolledAmountInList]);

  const getRowHeight = ({ index }) => {
    let extraHeight = 0;
    if (index === 0) {
      return spacerHeight;
    }

    const offsetIndex = index - 1;

    if (index === flightsToRender.length) {
      extraHeight = 84; //Makes room for the "Scan Ticket" button
    }

    return flightsToRender[offsetIndex]?.via
      ? VIA_FLIGHT_CARD_HEIGHT + extraHeight
      : DEFAULT_FLIGHT_CARD_HEIGHT + extraHeight;
  };

  const rowRenderer = ({ key, index, style }) => {
    if (index === 0) {
      return (
        <div id="spacer" key={key} style={style}>
          {noTerminalFlights && (
            <NoMatchMessage
              verticalOffset={spacerHeight - NO_MATCH_MESSAGE_HEIGHT}
            />
          )}
        </div>
      );
    }
    const offsetIndex = index - 1;
    return (
      <div key={key} style={style}>
        <FlightItem
          scrolledAmountInList={scrollPosition}
          key={flightsToRender[offsetIndex].ref_id}
          flight={flightsToRender[offsetIndex]}
          terminalToFilter={terminal}
          isHighlighted={
            lastSelectedFlightItem.id === flightsToRender[offsetIndex].ref_id
          }
        />
      </div>
    );
  };

  return (
    <StyledListContainer>
      <Header
        hasSearched={hasSearched}
        noTerminalFlights={noTerminalFlights}
        flightsCount={flightsToRender.length}
        scrollPosition={scrollPosition}
        terminal={terminal}
        setQueryParams={setQueryParams}
        terminalsOptionList={terminalsDropdownOptionList}
      />
      {areFlightsLoading && (
        <LoadingIndicator
          verticalOffset={spacerHeight}
          height="200px"
          variant={VARIANTS.LOADING_INDICATOR.ABSOLUTE}
        />
      )}
      {!areFlightsLoading && noFlights && (
        <EmptyList
          verticalOffset={spacerHeight}
          message={
            noFavoriteFlights
              ? t("flights_list.favorites_emptyList")
              : t("flights_list.emptyList")
          }
        />
      )}
      <List
        className="containerList"
        ref={listRef}
        onScroll={handleScroll}
        height={height}
        width={width}
        overscanRowCount={40}
        rowRenderer={rowRenderer}
        rowHeight={getRowHeight}
        estimatedRowSize={flightsToRender.length + 1} // +1 is the spacer
        rowCount={flightsToRender.length + 1}
      />
    </StyledListContainer>
  );
};

// ===========================
//    # Styled Components #
// ===========================

const StyledListContainer = styled.div`
  .containerList {
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
    -webkit-overscroll-behavior-y: none;
    -webkit-overscroll-behavior-x: none;
  }

  .containerList::-webkit-scrollbar {
    display: none;
  }
`;

export default FlightsList;
